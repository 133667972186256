// © 2023 Amazon Web Services, Inc. or its affiliates. All Rights Reserved.

// This AWS Content is provided subject to the terms of the AWS Customer Agreement
// available at http://aws.amazon.com/agreement or other written agreement between
// Customer and either Amazon Web Services, Inc. or Amazon Web Services EMEA SARL or both.

.live-indicator {
    -moz-transition: all 0.5s ease-in;
    -o-transition: all 0.5s ease-in;
    -webkit-transition: all 0.5s ease-in;
    transition: all 0.5s ease-in;
    height: 15px;
    width: 15px;
    border-radius: 100%;
    background-color: #d91515;
    animation: pulse-animation 2s infinite;
    margin-right: 4px;
}

.pulse {
    animation: pulse-animation 2s infinite;
}

@keyframes pulse-animation {
    0% {
        box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.2);
        opacity: 75%;
    }
    50% {
        opacity: 100%;
    }
    100% {
        box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
        opacity: 75%;
    }
}
